import { useQuery } from '@tanstack/vue-query'
import type { IAccuraitScheduleResponse } from '@audit'

export default (divisionId: string) =>
  useQuery({
    queryKey: ['divisions', divisionId, 'accurait-schedule'],
    queryFn: () => {
      if (!divisionId) {
        return Promise.resolve(undefined)
      }
      return fetchAPI<IAccuraitScheduleResponse>(
        `/companies/${divisionId}/dueDiligence/accuraitSchedule`,
      )
    },
  })
